import React from 'react';
import { useLoader } from 'react-three-fiber';
import { TextureLoader } from 'three';

const wallVertices = (w: number, h: number, l: number) => {
  const len = l + l + w + w;
  const x1 = 0;
  const x2 = w;

  const u1 = l / len
  const u2 = (w + l) / len
  const u3 = (w + l + l) / len

  const position = new Float32Array([
    x1, h, 0,
    x1, h, l,
    x1, 0, l,
    x1, 0, 0,

    x1, h, 0,
    x1, 0, 0,
    x2, 0, 0,
    x2, h, 0,

    x2, h, 0,
    x2, 0, 0,
    x2, 0, l,
    x2, h, l,

    x2, h, l,
    x2, 0, l,
    x1, 0, l,
    x1, h, l,
  ]);

  const uv = new Float32Array([
    u1, 0.0,
    0.0, 0.0,
    0.0, 1.0,
    u1, 1.0,

    u1, 0.0,
    u1, 1.0,
    u2, 1.0,
    u2, 0.0,

    u2, 0.0,
    u2, 1.0,
    u3, 1.0,
    u3, 0.0,

    u3, 0.0,
    u3, 1.0,
    1.0, 1.0,
    1.0, 0.0
  ]);

  return { position, uv };
};

export interface RoomProps {
  width: number;
  height: number;
  length: number;
  wall: any;
}

export default (props: RoomProps) => {
  const { width: w, height: h, length: l } = props;
  const { position, uv } = wallVertices(l, h, w);

  console.log('render room')
  const index = new Uint16Array([
    0, 1, 2, 2, 3, 0,
    4, 5, 6, 6, 7, 4,
    8, 9, 10, 10, 11, 8,
    12, 13, 14, 14, 15, 12
  ]);

  const texture = useLoader(TextureLoader, props.wall.img);

  return (
    <mesh
      { ...props }>
      <bufferGeometry attach="geometry">
        <bufferAttribute attach="index" array={ index } count={ index.length } itemSize={ 1 } />
        <bufferAttribute attachObject={ ['attributes', 'position'] } array={ position } count={ position.length / 3 } itemSize={ 3 } />
        <bufferAttribute attachObject={ ['attributes', 'uv'] } array={ uv } count={ uv.length / 2 } itemSize={ 2 } />
      </bufferGeometry>
      <meshBasicMaterial attach='material' map={ texture } />
    </mesh>

  )
}
