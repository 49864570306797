import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas, PerspectiveCameraProps, useFrame, useThree, extend, ReactThreeFiber } from "react-three-fiber";
import { Vector3 } from 'three';
import './App.css';
import Room from './Room';
import project from './example1.json';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

console.log(project);
extend({ OrbitControls });

type Module = typeof project.modules[0];

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'orbitControls': ReactThreeFiber.Object3DNode<OrbitControls, typeof OrbitControls>;
    }
  }
}

function Box(props: { module: Module }) {
  const { module: m } = props;

  return (
    <mesh
      position={ [m.x, m.y + m.h * 0.5, m.z] }
      rotation={ [0, m.ry * Math.PI / 180, 0] }
      { ...props }>
      <boxBufferGeometry args={ [m.w, m.h, m.d] } />
      <meshStandardMaterial color='orange' />
    </mesh>
  )
}

function Camera(props: PerspectiveCameraProps) {
  const ref = useRef<any>();
  const { setDefaultCamera } = useThree();
  useEffect(() => {
    setDefaultCamera(ref.current);
    ref.current.lookAt(0, 0, 0);
  }, [])
  useFrame(() => ref.current.updateMatrixWorld())
  return <perspectiveCamera ref={ ref } { ...props } />
}


const CameraControls = () => {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef<any>();
  useFrame((state) => controls.current.update());
  return <orbitControls ref={ controls } args={ [camera, domElement] } />;
};

function App() {
  const [camX] = useState(project.cam_x);
  const [camY] = useState(project.cam_y);
  const [camZ] = useState(project.cam_z);

  return (
    <Canvas style={ { height: '100%' } }>
      <CameraControls />
      <Camera position={ new Vector3(camX, camY, camZ) } far={ 1000000 } near={ 10 } />
      <ambientLight intensity={ 0.5 } />
      <spotLight position={ [camX, camY, camZ] } angle={ 1 } />

      <Suspense fallback={null}>
        <Room wall={ project.wall_c } width={ project.w } height={ project.h } length={ project.h } />
        {
          project.modules.map((m, index) => {
            return (
              <Box key={ index } module={ m } />
            )
          })
        }
      </Suspense>
    </Canvas>
  );
}

export default App;
